<template>
  <div>
    <v-sheet tile height="54" class="d-flex">
      <v-btn outlined class="ma-2" color="grey darken-2" @click="setToday">
        Hoje
      </v-btn>
      <v-autocomplete
        v-model="type"
        :items="types"
        item-text="text"
        item-value="value"
        dense
        outlined
        hide-details
        class="ma-2"
        label="Tipos"
      ></v-autocomplete>
      <v-autocomplete
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="Dias da Semana"
        class="ma-2"
      ></v-autocomplete>
      <v-btn
        outlined
        class="ma-2"
        color="grey darken-2"
        @click="() => atualizarListaProgramacoesFornecedor(params)"
        >Atualizar
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="showEvent"
        @change="getEvents"
      >
        <template v-slot:event="{ event, timed, eventSummary }">
          <div class="v-event-draggable" v-html="eventSummary()"></div>
          <div class="ma-2">
            <b>Distribuição para Parceiros:</b>
            <table
              style="width: 90%; background: white; color: black; margin: 10px"
              border="1px"
            >
              <thead>
                <tr>
                  <th style="padding: 1px">Designado</th>
                  <th style="padding: 1px">distribuidos</th>
                  <th style="padding: 1px">Restante</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="event.item.qt_caminhoes > 0">
                  <td style="padding: 1px">
                    {{
                      event.item.qt_caminhoes ? event.item.qt_caminhoes : '0'
                    }}
                  </td>
                  <td style="padding: 1px">
                    {{ quantidade_distribuida_caminhoes(event.item) }}
                  </td>
                  <td style="padding: 1px">
                    {{ quantidade_restante_caminhoes_a_distribuir(event.item) }}
                  </td>
                </tr>
                <tr v-if="event.item.qt_peso > 0">
                  <td style="padding: 1px">
                    {{ event.item.qt_peso ? event.item.qt_peso : '0' }} TON
                  </td>
                  <td style="padding: 1px">
                    {{ quantidade_distribuida_peso(event.item) }} TON
                  </td>
                  <td style="padding: 1px">
                    {{ quantidade_restante_peso_a_distribuir(event.item) }} TON
                  </td>
                </tr>
              </tbody>
            </table>
            <b>Caminhões atendidos por região:</b>
            <table
              style="width: 90%; background: white; color: black; margin: 10px"
              border="1px"
            >
              <thead>
                <tr>
                  <th style="padding: 1px">Produto</th>
                  <th style="padding: 1px">Região</th>
                  <th style="padding: 1px">Planta</th>
                  <th style="padding: 1px">Designado</th>
                  <th style="padding: 1px">Atendida</th>
                  <th style="padding: 1px">Restante</th>
                  <th style="padding: 1px">Performace</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="padding: 1px">
                    {{ event.item.programacao_unidade.sub_produto.descricao }}
                  </td>
                  <td style="padding: 1px">
                    {{
                      event.item.regiao
                        ? event.item.regiao.sigla
                        : 'Sem região!'
                    }}
                  </td>
                  <td style="padding: 1px">
                    {{ event.item.programacao_unidade.terminal.trade_name }}
                  </td>

                  <td v-if="event.item.qt_caminhoes > 0" style="padding: 1px">
                    {{ event.item.qt_caminhoes }}
                  </td>
                  <td v-if="event.item.qt_peso > 0" style="padding: 1px">
                    {{ event.item.qt_peso }} TON
                  </td>

                  <td v-if="event.item.qt_caminhoes > 0" style="padding: 1px">
                    {{ quantidade_atendida(event.item) }}
                  </td>
                  <td v-if="event.item.qt_caminhoes > 0" style="padding: 1px">
                    {{ quantidade_restante_caminhoes(event.item) }}
                  </td>
                  <td v-if="event.item.qt_caminhoes > 0" style="padding: 1px">
                    {{ performace_caminhoes(event.item) }}
                  </td>

                  <td v-if="event.item.qt_peso > 0" style="padding: 1px">
                    {{ quantidade_agendada_peso(event.item) }} TON
                  </td>
                  <td v-if="event.item.qt_peso > 0" style="padding: 1px">
                    {{ quantidade_restante_peso(event.item) }} TON
                  </td>
                  <td v-if="event.item.qt_peso > 0" style="padding: 1px">
                    {{ performace_caminhoes_peso(event.item) }} TON
                  </td>
                </tr>
              </tbody>
            </table>
            <b>Cotas distribuidas:</b>
            <table
              style="width: 90%; background: white; color: black; margin: 10px"
              border="1px"
            >
              <thead>
                <tr>
                  <th style="padding: 1px">Parceiro</th>
                  <th style="padding: 1px">Designado</th>
                  <th style="padding: 1px">Atendidos</th>
                  <th style="padding: 1px">Restante</th>
                  <th style="padding: 1px">Performace</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in event.item.programacoes_regiao"
                  :key="index"
                >
                  <td style="padding: 1px">{{ item.cliente.business_name }}</td>
                  <td v-if="item.qt_caminhoes > 0" style="padding: 1px">
                    {{ item.qt_caminhoes }}
                  </td>
                  <td v-if="item.qt_peso > 0" style="padding: 1px">
                    {{ item.qt_peso }}
                  </td>

                  <td v-if="item.qt_caminhoes > 0" style="padding: 1px">
                    {{ quantidade_atendida(item) }}
                  </td>
                  <td v-if="item.qt_caminhoes > 0" style="padding: 1px">
                    {{ quantidade_restante_caminhoes(item) }}
                  </td>
                  <td v-if="item.qt_caminhoes > 0" style="padding: 1px">
                    {{ performace_caminhoes(item) }}
                  </td>

                  <td v-if="item.qt_peso > 0" style="padding: 1px">
                    {{ quantidade_agendada_peso(item) }}
                  </td>
                  <td v-if="item.qt_peso > 0" style="padding: 1px">
                    {{ quantidade_restante_peso(item) }}
                  </td>
                  <td v-if="item.qt_peso > 0" style="padding: 1px">
                    {{ performace_caminhoes_peso(item) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <ul>
            <li>
              <b>Tipo operação: </b>
              {{
                event.item.programacao_unidade.tipo_operacao
                  ? event.item.programacao_unidade.tipo_operacao.descricao
                  : 'Sem operação!'
              }}
            </li>
            <li>
              <b>Região: </b>
              {{ event.item.regiao ? event.item.regiao.sigla : 'Sem região!' }}
            </li>
            <li v-for="(regra, index) in event.item.tipos_frete" :key="index">
              <b>Tipo de frete: </b> {{ regra.descricao }}
            </li>
            <li>
              <b>Fornecedor: </b>
              {{
                event.item.programacao_unidade.fornecedor
                  ? event.item.programacao_unidade.fornecedor.trade_name
                  : 'Sem fornecedor!'
              }}
            </li>
            <li v-if="event.item.programacao_unidade.terminal">
              <b>Terminal: </b>
              {{ event.item.programacao_unidade.terminal.trade_name }}
            </li>
            <li v-if="event.item.programacao_unidade.produto">
              <b>Produto: </b>
              {{ event.item.programacao_unidade.produto.descricao }}
            </li>
            <li v-if="event.item.programacao_unidade.sub_produto">
              <b>Subproduto: </b>
              {{ event.item.programacao_unidade.sub_produto.descricao }}
            </li>
            <li>Peso: {{ event.item.qt_peso ? event.item.qt_peso : '0' }}</li>
            <li>
              <b>Caminhões: </b>
              {{ event.item.qt_caminhoes ? event.item.qt_caminhoes : '0' }}
            </li>
            <br />
          </ul>
        </template>
      </v-calendar>
    </v-sheet>
    <v-dialog
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-container>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <form>
              <v-card-title>
                <span class="headline">Programação Regiao</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="3">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoFornecedor.programacao_unidade.proprietario
                        "
                        label="Gate/Unidade"
                        :items="[
                          programacaoFornecedor.programacao_unidade
                            .proprietario,
                        ]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        return-object
                        prepend-icon="mdi-boom-gate"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoFornecedor.programacao_unidade.fornecedor
                        "
                        label="Fornecedor"
                        :items="[
                          programacaoFornecedor.programacao_unidade.fornecedor,
                        ]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        return-object
                        prepend-icon="mdi-equalizer"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoFornecedor.programacao_unidade
                            .tipo_operacao
                        "
                        label="Tipo de Operação"
                        :items="
                          programacaoFornecedor.programacao_unidade
                            .tipo_controle
                            ? [
                                programacaoFornecedor.programacao_unidade
                                  .tipo_controle,
                              ]
                            : []
                        "
                        item-text="descricao"
                        item-value="id"
                        return-object
                        prepend-icon="mdi-clipboard-flow-outline"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoFornecedor.programacao_unidade.terminal
                        "
                        label="Terminal"
                        :items="[
                          programacaoFornecedor.programacao_unidade.terminal,
                        ]"
                        :item-text="
                          (item) =>
                            item.business_name +
                            ' - ' +
                            `${item.cnpj != null ? item.cnpj : item.cpf}`
                        "
                        item-value="public_id"
                        return-object
                        prepend-icon="mdi-alpha-t-box-outline"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-divider class="my-5"></v-divider>
                  <v-row>
                    <v-col class="pt-0" cols="12" sm="4">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="
                          programacaoFornecedor.programacao_unidade
                            .tipo_controle
                        "
                        label="Tipo de controle"
                        :items="
                          () => {
                            i =
                              programacaoFornecedor.programacao_unidade
                                .tipo_controle
                            return i ? [i] : []
                          }
                        "
                        :item-text="descricao"
                        prepend-icon="mdi-clipboard-alert-outline"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="pt-0" cols="12" sm="4">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoFornecedor.programacao_unidade.modalidades
                        "
                        label="Tipos de Veículo"
                        :items="
                          programacaoFornecedor.programacao_unidade.modalidades
                        "
                        item-text="descricao"
                        item-value="id"
                        multiple
                        chips
                        prepend-icon="mdi-truck-outline"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="pt-0" cols="12" sm="4">
                      <v-autocomplete
                        readonly
                        v-model="
                          programacaoFornecedor.programacao_unidade.carrocerias
                        "
                        label="Tipos de Carrocerias"
                        :items="
                          programacaoFornecedor.programacao_unidade.carrocerias
                        "
                        item-text="descricao"
                        item-value="id"
                        multiple
                        chips
                        prepend-icon="mdi-truck-outline"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col class="pt-0" cols="12" sm="12">
                      <v-text-field
                        readonly
                        v-model="
                          programacaoFornecedor.programacao_unidade.observacao
                        "
                        :value="
                          programacaoFornecedor.programacao_unidade.observacao
                        "
                        label="Observação"
                        prepend-icon="mdi-note-text-outline"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" lg="3">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="
                          programacaoFornecedor.programacao_unidade.produto
                        "
                        label="Produto"
                        :items="
                          programacaoFornecedor.programacao_unidade.produto
                            ? [
                                programacaoFornecedor.programacao_unidade
                                  .produto,
                              ]
                            : []
                        "
                        item-text="descricao"
                        item-value="id"
                        return-object
                        prepend-icon="mdi-alpha-p-box-outline"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="
                          programacaoFornecedor.programacao_unidade.sub_produto
                        "
                        :items="
                          programacaoFornecedor.programacao_unidade.sub_produto
                            ? [
                                programacaoFornecedor.programacao_unidade
                                  .sub_produto,
                              ]
                            : []
                        "
                        item-text="descricao"
                        label="Sub Produto"
                        item-value="id"
                        return-object
                        prepend-icon="mdi-alpha-p-circle-outline"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="12" md="3" lg="3">
                      <v-autocomplete
                        readonly
                        class="mt-3"
                        v-model="programacaoFornecedor.regiao"
                        label="Região"
                        :items="[programacaoFornecedor.regiao]"
                        item-text="sigla"
                        item-value="public_id"
                        prepend-icon="mdi-home-city"
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="3" lg="3">
                      <v-autocomplete
                        readonly
                        v-model="programacaoFornecedor.tipos_frete"
                        :items="programacaoFornecedor.tipos_frete"
                        item-text="descricao"
                        item-value="id"
                        label="Tipo de Frete"
                        prepend-icon="mdi-truck"
                        chips
                        multiple
                        return-object
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" lg="6">
                      <div class="mt-5 d-flex justify-space-around">
                        <label>Data inicial:</label>
                        <input
                          type="datetime-local"
                          readonly
                          name="datetime"
                          :value="
                            date2iso(
                              new Date(
                                programacaoFornecedor.programacao_unidade.data_inicio
                              )
                            )
                          "
                        />
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6">
                      <div class="mt-5 d-flex justify-space-around">
                        <label>Data final:</label>
                        <input
                          type="datetime-local"
                          readonly
                          name="datetime"
                          :value="
                            date2iso(
                              new Date(
                                programacaoFornecedor.programacao_unidade.data_fim
                              )
                            )
                          "
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>

                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-divider></v-divider>
                <v-divider></v-divider>

                <v-container>
                  <v-card-title v-if="canCreate">
                    <span class="headline">Distribuir nova cota</span>
                  </v-card-title>
                  <v-row v-if="canCreate">
                    <v-col cols="11">
                      <v-row>
                        <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                          <v-autocomplete
                            class="mt-3"
                            v-model="editedItem.cliente"
                            label="Cliente"
                            :items="theClientes"
                            :item-text="
                              (item) =>
                                item.business_name +
                                ' - ' +
                                `${item.cnpj != null ? item.cnpj : item.cpf}`
                            "
                            item-value="public_id"
                            hint="Selecione o cliente."
                            persistent-hint
                            prepend-icon="mdi-account"
                            required
                            return-object
                            @keyup="customChange($event, handleGetClientes)"
                            :loading="calling"
                          ></v-autocomplete>
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="12" lg="2" md="2">
                          <v-autocomplete
                            v-model="editedItem.pedidos"
                            :items="
                              pedidos.results.filter(
                                (pedido) =>
                                  pedido.cliente.public_id ==
                                  (editedItem.cliente
                                    ? editedItem.cliente.public_id
                                    : '')
                              )
                            "
                            item-text="numero_pedido"
                            item-value="id"
                            label="Pedidos"
                            prepend-icon="mdi-ticket-confirmation-outline"
                            chips
                            clearable
                            deletable-chips
                            multiple
                            hint="Selecione os pedidos."
                            persistent-hint
                            required
                            return-object
                          ></v-autocomplete>
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                          <v-text-field
                            class="mt-3"
                            type="number"
                            v-model="editedItem.qt_peso"
                            label="Toneladas"
                            :max="qt_peso(programacaoFornecedor)"
                            :min="0"
                            prepend-icon="mdi-weight"
                            hint="Indique quantas Toneladas será programada."
                            persistent-hint
                            :rules="[
                              () =>
                                editedItem.qt_peso <=
                                  qt_peso(programacaoFornecedor) ||
                                'Quantidade de toneladas é maior que a quantidade permitida.',
                            ]"
                          >
                            <template v-slot:label>
                              Restante: {{ qt_peso(programacaoFornecedor) }}
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                          <v-text-field
                            class="mt-3"
                            type="number"
                            v-model="editedItem.qt_caminhoes"
                            label="Caminhões"
                            :max="qt_caminhoes(programacaoFornecedor)"
                            :min="0"
                            :disabled="qt_caminhoes(programacaoFornecedor) == 0"
                            prepend-icon="mdi-truck-check-outline"
                            hint="Indique quantos Caminhões será programado."
                            persistent-hint
                            :rules="[
                              () =>
                                editedItem.qt_caminhoes <=
                                  qt_caminhoes(programacaoFornecedor) ||
                                'Quantidade de caminhões é maior que a quantidade permitida.',
                            ]"
                          >
                            <template v-slot:label>
                              Restante:
                              {{
                                quantidade_restante_caminhoes_a_distribuir(
                                  programacaoFornecedor
                                )
                              }}
                            </template>
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        block
                        color="info"
                        class="text-capitalize"
                        @click="addProgramacao"
                        :disabled="
                          (qt_peso(programacaoFornecedor) <= 0 &&
                            qt_caminhoes(programacaoFornecedor) <= 0) ||
                          editedItem.qt_peso > qt_peso(programacaoFornecedor) ||
                          editedItem.qt_caminhoes >
                            qt_caminhoes(programacaoFornecedor)
                        "
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <br />
                  <div
                    v-if="programacaoFornecedor.programacoes_regiao.length > 0"
                  >
                    <v-divider></v-divider>
                    <v-card-title>
                      <span class="headline">Editar distribuição de cotas</span>
                    </v-card-title>
                    <v-row
                      v-for="(
                        programacao, i
                      ) in programacaoFornecedor.programacoes_regiao"
                      v-bind:key="i"
                    >
                      <v-col cols="10">
                        <v-row>
                          <v-col class="pt-0" cols="12" sm="12" lg="4" md="4">
                            <validation-provider
                              v-slot="{ errors }"
                              name="cliente"
                              rules="required"
                            >
                              <v-autocomplete
                                class="mt-3"
                                v-model="
                                  programacaoFornecedor.programacoes_regiao[i]
                                    .cliente
                                "
                                label="Cliente"
                                :items="theClientes"
                                :error-messages="errors"
                                :item-text="
                                  (item) =>
                                    item.business_name +
                                    ' - ' +
                                    `${
                                      item.cnpj != null ? item.cnpj : item.cpf
                                    }`
                                "
                                item-value="public_id"
                                hint="Selecione o cliente."
                                persistent-hint
                                prepend-icon="mdi-account"
                                required
                                return-object
                                @keyup="customChange($event, handleGetClientes)"
                                :loading="calling"
                              ></v-autocomplete>
                            </validation-provider>
                          </v-col>

                          <v-col class="pt-0" cols="12" sm="12" lg="2" md="2">
                            <validation-provider
                              v-slot="{ errors }"
                              name="pedidos"
                            >
                              <v-autocomplete
                                v-model="
                                  programacaoFornecedor.programacoes_regiao[i]
                                    .pedidos
                                "
                                :items="
                                  pedidos.results.filter(
                                    (pedido) =>
                                      pedido.cliente.public_id ==
                                      (programacaoFornecedor
                                        .programacoes_regiao[i].cliente
                                        ? programacaoFornecedor
                                            .programacoes_regiao[i].cliente
                                            .public_id
                                        : '')
                                  )
                                "
                                item-text="numero_pedido"
                                item-value="public_id"
                                :error-messages="errors"
                                label="Pedidos"
                                prepend-icon="mdi-ticket-confirmation-outline"
                                chips
                                clearable
                                deletable-chips
                                multiple
                                hint="Selecione os pedidos."
                                persistent-hint
                                return-object
                              ></v-autocomplete>
                            </validation-provider>
                          </v-col>

                          <v-col
                            v-if="programacaoFornecedor.qt_peso !== 0"
                            class="pt-0"
                            cols="12"
                            sm="12"
                            lg="1"
                            md="1"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="qt_peso"
                              rules="required"
                            >
                              <v-text-field
                                class="mt-3"
                                type="number"
                                v-model="
                                  programacaoFornecedor.programacoes_regiao[i]
                                    .qt_peso
                                "
                                label="Toneladas"
                                :max="
                                  programacaoFornecedor.programacoes_regiao[i]
                                    .qt_peso *
                                    1 +
                                  qt_peso(programacaoFornecedor)
                                "
                                :min="0"
                                :disabled="programacaoFornecedor.qt_peso == 0"
                                prepend-icon="mdi-weight"
                                :error-messages="errors"
                                hint="Indique quantas Toneladas será programada."
                                persistent-hint
                              >
                              </v-text-field>
                            </validation-provider>
                          </v-col>

                          <v-col class="pt-0" cols="12" sm="12" lg="3" md="3">
                            <validation-provider
                              v-slot="{ errors }"
                              name="qt_caminhoes"
                              rules="required"
                            >
                              <v-text-field
                                class="mt-3"
                                type="number"
                                v-model="
                                  programacaoFornecedor.programacoes_regiao[i]
                                    .qt_caminhoes
                                "
                                label="Caminhões"
                                :max="
                                  programacaoFornecedor.programacoes_regiao[i]
                                    .qt_caminhoes *
                                    1 +
                                  qt_caminhoes(programacaoFornecedor)
                                "
                                :min="0"
                                :disabled="
                                  programacaoFornecedor.qt_caminhoes == 0
                                "
                                prepend-icon="mdi-truck-check-outline"
                                :error-messages="errors"
                                hint="Indique quantos Caminhões será programado."
                                persistent-hint
                              >
                              </v-text-field>
                            </validation-provider>
                          </v-col>

                          <v-col class="pt-0" cols="12" sm="12" lg="2" md="2">
                            <label style="font-size: 20px"
                              >Atendidos:
                              <b>{{
                                quantidade_atendida(
                                  programacaoFornecedor.programacoes_regiao[i]
                                )
                              }}</b></label
                            >
                            <hr />
                            <label style="font-size: 20px"
                              >Restante:
                              <b>{{
                                quantidade_restante_caminhoes(
                                  programacaoFornecedor.programacoes_regiao[i]
                                )
                              }}</b></label
                            >
                            <hr />
                            <label style="font-size: 20px"
                              >Performace:
                              <b>{{
                                performace_caminhoes(
                                  programacaoFornecedor.programacoes_regiao[i]
                                )
                              }}</b></label
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="2">
                        <v-row>
                          <v-col cols="4">
                            <v-btn
                              color="error"
                              class="1"
                              @click="removeProgramacao(i)"
                              ><v-icon>mdi-delete</v-icon></v-btn
                            >
                          </v-col>
                          <v-col cols="8">
                            <v-btn
                              color="info"
                              class="1"
                              @click="
                                detalhesDistribuicaoCliente(
                                  programacaoFornecedor.programacoes_regiao[i]
                                )
                              "
                            >
                              Detalhes
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row v-if="canCreate">
                    <v-col cols="12" md="12" lg="6">
                      <label>Replicar:</label>
                      <br />
                      <v-btn-toggle v-model="replicar" multiple>
                        <v-btn :disabled="!replicavel(DAYS.PRIMEIRO)">
                          {{ texto(DAYS.PRIMEIRO, programacaoFornecedor) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.SEGUNDO)">
                          {{ texto(DAYS.SEGUNDO, programacaoFornecedor) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.TERCEIRO)">
                          {{ texto(DAYS.TERCEIRO, programacaoFornecedor) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.QUARTO)">
                          {{ texto(DAYS.QUARTO, programacaoFornecedor) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.QUINTO)">
                          {{ texto(DAYS.QUINTO, programacaoFornecedor) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.SEXTO)">
                          {{ texto(DAYS.SEXTO, programacaoFornecedor) }}
                        </v-btn>
                        <v-btn :disabled="!replicavel(DAYS.SETIMO)">
                          {{ texto(DAYS.SETIMO, programacaoFornecedor) }}
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col cols="12" class="d-flex justify-space-between">
                    <div>
                      <router-link
                        v-if="
                          !(
                            qt_peso(programacaoFornecedor) == 0 &&
                            qt_caminhoes(programacaoFornecedor) == 0
                          ) &&
                          editavel &&
                          notSuspended &&
                          !invalid
                        "
                        :to="{
                          name: 'NovoAgendamento',
                          params: {
                            ...paramsToAgendamento(programacaoFornecedor),
                          },
                        }"
                      >
                        <v-btn color="primary" small>Novo Agendamento</v-btn>
                      </router-link>
                      <v-btn
                        small
                        class="mx-3"
                        @click="detalhesDistribuicao(programacaoFornecedor)"
                      >
                        Agendamentos
                      </v-btn>
                    </div>
                    <v-btn
                      small
                      :disabled="invalid || !editavel || !notSuspended"
                      @click="saveAll"
                    >
                      Salvar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </form>
          </validation-observer>
        </v-container>
      </v-card>
      <v-overlay :value="carregaDetalhes">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
    </v-dialog>
    <v-dialog v-model="detalhar">
      <detalhes-programacao
        :detalhes="detalhes"
        :title="title"
        :headers="headersDetalhes"
        :download="download"
        @close="closeModal()"
      />
    </v-dialog>
  </div>
</template>
<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  paramsToAgendamento,
  contabilizadores,
  pesquisa,
} from '@/mixins/cotas.js'
import { DAYS, date2iso } from '@/mixins/days.js'
import { replicacao } from '@/mixins/replicacao.js'
import { customChange } from '@/mixins/autocomplete.js'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import ClienteApi from '@/services/tenants/clientes.js'
import DetalhesProgramacao from '@/components/patio/Programacao/Detalhes/DetalhesProgramacao'

export default {
  name: 'CalendarioProgramacaoRegiao',
  props: ['params'],
  mixins: [
    paramsToAgendamento,
    contabilizadores,
    pesquisa,
    replicacao,
    customChange,
  ],
  components: {
    DetalhesProgramacao,
  },
  data() {
    return {
      detalhes: [],
      title: {},
      download: {
        type: '',
        public_id: '',
      },
      detalhar: false,
      headersDetalhes: [
        {
          text: 'Veículo',
          value: 'veiculo',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Data da Cota',
          value: 'data_cota',
        },
        {
          text: 'Triado Em',
          value: 'triado_em',
        },
        {
          text: 'Liberado Em',
          value: 'saida_do_patio',
        },
        {
          text: 'Tempo em patio',
          value: 'tempo_em_patio',
        },
        {
          text: 'Finalizado Em',
          value: 'chegada_terminal',
        },
        {
          text: 'Cliente',
          value: 'cliente',
        },
        {
          text: 'Produto',
          value: 'produto',
        },
        {
          text: 'Sub Produto',
          value: 'sub_produto',
        },
        {
          text: 'Nota fiscal',
          value: 'num_nota_fiscal',
        },
        {
          text: 'Quantidade (KG)',
          value: 'quantidade',
        },
        {
          text: 'Embalagem',
          value: 'embalagem',
        },
        {
          text: 'Modalidade',
          value: 'modalidade',
        },
        {
          text: 'Eixos',
          value: 'eixos',
        },
      ],
      carregaDetalhes: false,
      type: 'week',
      types: [
        { text: 'Mês', value: 'month' },
        { text: 'Semana', value: 'week' },
        { text: 'Dia', value: 'day' },
      ],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [
        DAYS.DOMINGO,
        DAYS.SEGUNDA,
        DAYS.TERCA,
        DAYS.QUARTA,
        DAYS.QUINTA,
        DAYS.SEXTA,
        DAYS.SABADO,
      ],
      weekdays: [
        {
          text: 'Domingo - Sábado',
          value: [
            DAYS.DOMINGO,
            DAYS.SEGUNDA,
            DAYS.TERCA,
            DAYS.QUARTA,
            DAYS.QUINTA,
            DAYS.SEXTA,
            DAYS.SABADO,
          ],
        },
        {
          text: 'Segunda - Sexta',
          value: [
            DAYS.SEGUNDA,
            DAYS.TERCA,
            DAYS.QUARTA,
            DAYS.QUINTA,
            DAYS.SEXTA,
          ],
        },
      ],
      value: '',
      events: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      colors: [
        'blue',
        'indigo',
        'deep-purple',
        'cyan',
        'green',
        'orange',
        'grey darken-1',
      ],
      selectFornecedor: [],
      selectProduto: [],
      selectSubProduto: [],
      selectTerminal: [],
      tab: null,
      editedIndex: -1,
      defaultItem: {
        public_id: '',
        qt_peso: 0,
        qt_caminhoes: 0,
        cliente: null,
      },
      editedItem: {
        public_id: '',
        qt_peso: 0,
        qt_caminhoes: 0,
        cliente: null,
      },
      clientes: [],
    }
  },
  computed: {
    ...mapState('patio', ['pedidos']),
    ...mapState('programacao', [
      'programacaoFornecedor',
      'programacoesFornecedor',
      'params',
    ]),
    ...mapState('auth', ['empresaAtual']),
    theClientes() {
      let clientes_programacoes = this.programacaoFornecedor.programacoes_regiao.map(
        (prog) => prog.cliente
      )
      return []
        .concat(clientes_programacoes, this.clientes, this.editedItem.cliente)
        .filter((item) => item)
    },
    editavel() {
      if (this.programacaoFornecedor) {
        if (
          new Date(this.programacaoFornecedor.programacao_unidade.data_fim) >
          new Date()
        ) {
          return true
        }
      }
      return false
    },
    suspendivel() {
      if (this.editedIndex >= 0) {
        if (new Date(this.editedItem.data_fim) > new Date()) {
          return true
        }
      }
      return false
    },
    notSuspended() {
      if (this.programacaoFornecedor) {
        return this.programacaoFornecedor.programacao_unidade.suspendido == 0
      }
      return true
    },
    allowedToAdd() {
      return this.empresaAtual.gate
    },
    formTitle() {
      return this.editedIndex === -1
        ? 'Distribuir nova cota'
        : 'Editar cota distribuida'
    },
    permission() {
      return Sequences.CotasRegiao.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    this.date2iso = date2iso
  },
  watch: {},
  methods: {
    ...mapActions('patio', ['getPedidos', 'deleteProgramacaoRegiao']),
    ...mapActions('programacao', [
      'getProgramacoesFornecedor',
      'getProgramacaoFornecedor',
      'addProgramacaoRegiao',
      'editProgramacaoRegiao',
      'getProgramacaoDetalhe',
    ]),
    ...mapMutations('programacao', ['SET_PROGRAMACAO_FORNECEDOR']),
    ...mapMutations('patio', ['setPedido', 'SET_PEDIDOS']),
    ...mapMutations('clientes', ['setClientes']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async closeModal() {
      this.detalhar = false
      this.detalhes = []
      this.title = {}
    },
    async detalhesDistribuicao(item) {
      try {
        this.carregaDetalhes = true
        const data = await this.getProgramacaoDetalhe({
          type: 'Fornecedor',
          public_id: item.public_id,
        })
        this.detalhes = data.agendamentos
        this.title = data.title
        this.detalhar = true
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Erro ao buscar detalhes. Tente novamente ou contacte o suporte.'
        )
      } finally {
        this.carregaDetalhes = false
      }
    },
    async detalhesDistribuicaoCliente(item) {
      try {
        this.carregaDetalhes = true
        const data = await this.getProgramacaoDetalhe({
          type: 'Regiao',
          public_id: item.public_id,
        })
        this.detalhes = data.agendamentos
        this.title = data.title
        this.download.type = 'Regiao'
        this.download.public_id = item.public_id
        this.detalhar = true
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Erro ao buscar detalhes. Tente novamente ou contacte o suporte.'
        )
      } finally {
        this.carregaDetalhes = false
      }
    },
    async handleGetClientes(event) {
      const value = event.target.value
      this.clientes = await ClienteApi.getClientes(
        this.empresaAtual.public_id,
        {
          pesquisa: value,
        }
      )
    },
    async atualizarListaPedidos(item) {
      item
      this.SET_PEDIDOS({ results: [], count: 0 })
      // await this.getPedidos({
      //   fornecedor: item.programacao_unidade.fornecedor?.fornecedor_id,
      //   produto: item.programacao_unidade.produto?.id,
      //   subProduto: item.programacao_unidade.sub_produto?.id,
      //   terminal: item.programacao_unidade.terminal.cliente_id,
      //   page_size: 1000,
      //   // valido: true,
      // })
      // console.log(this.pedidos)
    },
    addProgramacao() {
      this.programacaoFornecedor.programacoes_regiao.push({
        ...this.editedItem,
      })
      Object.assign(this.editedItem, this.defaultItem)
    },
    async removeProgramacao(index) {
      let programacao = this.programacaoFornecedor.programacoes_regiao[index]
      if (programacao.public_id != '') {
        try {
          await this.deleteProgramacaoRegiao(programacao)
          this.programacaoFornecedor.programacoes_regiao.splice(index, 1)
        } catch (error) {
          if (error.response) {
            if (error.response.data) {
              const data = error.response.data
              if (data.error_quantidade) {
                this.errorMessage(data.error_quantidade)
                if (data.novo_qt_peso) {
                  programacao.qt_peso = data.novo_qt_peso
                }
                if (data.novo_qt_caminhoes) {
                  programacao.qt_caminhoes = data.novo_qt_caminhoes
                }
                return
              }
            }
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
      this.programacaoFornecedor.programacoes_regiao.splice(index, 1)
    },
    async saveAll() {
      let errors = []
      await Promise.all(
        this.programacaoFornecedor.programacoes_regiao.map(
          async (programacao) => {
            try {
              let prog_regiao = await this.save(
                {
                  ...programacao,
                  programacao_fornecedor: this.programacaoFornecedor,
                },
                true
              )
              Object.assign(programacao, prog_regiao)
            } catch (error) {
              errors.push({ programacao: programacao, error: error })
            }
          }
        )
      )
      const map_errors = errors.map((item) => {
        const ret = {}
        let error = null
        if (
          item.error.response &&
          (item.error.response.status == 403 ||
            item.error.response.status == 404)
        ) {
          error = 'Empresa atual não pode cadastrar/editar programações.'
        } else {
          if (item.error.response) {
            error = item.error.response.data
          } else if (item.error.message) {
            error = item.error.message
          } else {
            error = item.error
          }
          if (item.error.response && 'qt_caminhoes' in error) {
            error = error.qt_caminhoes
          }
          if (item.error.response && 'qt_peso' in error) {
            error = error.qt_peso
          }
        }
        const key =
          `${item.programacao.cliente.business_name}` +
          ' - ' +
          `${
            item.programacao.cliente.cnpj != null
              ? item.programacao.cliente.cnpj
              : item.programacao.cliente.cpf
          }`
        ret[key] = error
        return ret
      })
      if (map_errors.length > 0) {
        this.errorMessage(map_errors)
      }
    },
    async save(programacao, skip_errors = false) {
      try {
        let prog_regiao
        if (programacao.public_id != '') {
          prog_regiao = await this.editProgramacaoRegiao(programacao)
          if (this.replicar.length > 0) {
            await this.editarReplicas(
              prog_regiao,
              this.editProgramacaoRegiao,
              this.addProgramacaoRegiao
            )
          }
        } else {
          prog_regiao = await this.addProgramacaoRegiao(programacao)
          if (this.replicar.length > 0) {
            await this.cadastrarReplicas(prog_regiao, this.addProgramacaoRegiao)
          }
        }
        this.close()
        return prog_regiao
      } catch (error) {
        console.log(error)
        if (skip_errors) {
          return Promise.reject(error)
        }
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage(
            'Empresa atual não pode cadastrar/editar programações.'
          )
        } else {
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
    },
    async editItem(item) {
      this.replicar = []
      this.editReplicas(item, this.programacoesFornecedor)
      await this.atualizarListaPedidos(item)
      this.SET_PROGRAMACAO_FORNECEDOR(item)
    },
    close() {
      this.selectedOpen = false
    },
    async atualizarListaProgramacoesFornecedor(params = null) {
      const selectedAtLeastOneRegion =
        params.regiao.filter((r) => r.public_id).length > 0
      if (!selectedAtLeastOneRegion) {
        alert('Necessário selecionar pelo menos 1 região para buscar os dados.')
        return
      }
      try {
        if (params == null) {
          params = this.params
        }
        let filtros = this.pesquisa(params)
        if (!filtros.data_inicio) {
          filtros.data_inicio = new Date(
            new Date(new Date().setHours(0, 0, 0, 0)).setDate(
              new Date().getDate() - new Date().getDay()
            )
          )
            .toISOString()
            .split('T')[0]
        }
        await this.getProgramacoesFornecedor(filtros)
        this.events = []
        this.getEvents()
      } catch (error) {
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa não possui acesso as programações.')
        } else {
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
    },
    createName(programacao) {
      let name = ''
      name += programacao.programacao_unidade.fornecedor
        ? programacao.programacao_unidade.fornecedor.trade_name
          ? programacao.programacao_unidade.fornecedor.trade_name
          : programacao.programacao_unidade.fornecedor.fornecedor_trade_name
        : ''
      if (programacao.programacao_unidade.produto) {
        name += ' - ' + programacao.programacao_unidade.produto.descricao
      }
      if (programacao.programacao_unidade.sub_produto) {
        name += ' - ' + programacao.programacao_unidade.sub_produto.descricao
      }
      // if (programacao.programacao_unidade.qt_caminhoes) {
      //   name += ' - ' + programacao.programacao_unidade.qt_caminhoes + ' Cam.'
      // }
      // if (programacao.programacao_unidade.qt_peso) {
      //   name += ' - ' + programacao.programacao_unidade.qt_peso + ' Ton.'
      // }
      return name
    },
    getEvents() {
      console.log(this.programacoesFornecedor)
      this.programacoesFornecedor.forEach((element) => {
        let i = this.events.findIndex(
          (event) => event.item.public_id == element.public_id
        )
        let start = new Date(element.programacao_unidade.data_inicio)
        let end = new Date(element.programacao_unidade.data_fim)
        if (i == -1) {
          this.events.push({
            item: element,
            name: this.createName(element),
            color:
              element.programacao_unidade.suspendido == 1
                ? 'red'
                : element.programacao_unidade.tipo_operacao
                ? element.programacao_unidade.tipo_operacao.id == 1
                  ? this.colors[4]
                  : this.colors[0]
                : this.colors[0],
            start: start.getTime(),
            end: end.getTime(),
            timed: true,
          })
        } else {
          this.events[i] = {
            item: element,
            name: this.createName(element),
            color:
              element.programacao_unidade.suspendido == 1
                ? 'red'
                : element.programacao_unidade.tipo_operacao
                ? element.programacao_unidade.tipo_operacao.id == 1
                  ? this.colors[4]
                  : this.colors[0]
                : this.colors[0],
            start: start.getTime(),
            end: end.getTime(),
            timed: true,
          }
        }
      })
    },
    setToday() {
      this.value = ''
    },
    async showEvent({ nativeEvent, event }) {
      if (this.canUpdate) {
        const open = async () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          const item = await this.getProgramacaoFornecedor(event.item.public_id)
          this.SET_PROGRAMACAO_FORNECEDOR(item)
          await this.editItem(item)
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.selectedOpen = true))
          )
        }
        if (this.selectedOpen) {
          this.selectedOpen = false
          requestAnimationFrame(() =>
            requestAnimationFrame(async () => await open())
          )
        } else {
          await open()
        }

        nativeEvent.stopPropagation()
      }
    },
    getEventColor(event) {
      const rgb = parseInt(event.color.substring(1), 16)
      const r = (rgb >> 16) & 0xff
      const g = (rgb >> 8) & 0xff
      const b = (rgb >> 0) & 0xff

      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event.color
    },
  },
}
</script>
